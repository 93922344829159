<template>
  <v-card
      class="mx-auto"
      style="z-index: 1029; margin-bottom: 0 !important;border-radius: 0"
      :class="$store.state.sidebarShow === 'responsive' ? 'sidebarShowed mobile-w-sidebar-opened' : 'sidebarHidden mobile-w-sidebar-closed' "
  >
    <v-icon
        v-if="$vuetify.breakpoint.width < 480"
        :class="$store.state.sidebarShow === 'responsive' ? 'mobile-toggle-closed' : 'mobile-d-none'"
        @click="$store.commit('toggleSidebarMobile')"
        size="36"
        style="position: absolute !important; right: -36px; color: #ffffff"
    >mdi-close
    </v-icon>
    <v-navigation-drawer permanent>
      <v-list-item style="padding: 0 10px !important;">
        <v-list-item-content v-if="$store.state.sidebarShow === 'responsive'">
          <img src="https://clnb.ru/dist/images/logo.svg" class="logo" alt="logo"
               style="max-width: 150px;margin-top: -10px">
        </v-list-item-content>
        <v-list-item-content v-else>
          <img src="../assets/icons/logo_clnb_ro_mini.svg" class="logo" alt="logo-mini" style="max-width: 28px">
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <div style="display: flex;flex-direction: column;
    justify-content: space-between;
    max-height: calc(100vh - 56px);
    height: calc(100vh - 56px);
    overflow-y: scroll;padding-bottom: 80px;"
           v-if="$store.state.user.rights && $store.state.user.rights.length > 0">
        <v-list
            dense
            nav>
          <template v-for="item in nav">
            <v-list-item
                @click="closeSidebar"
                v-if="!item.children && hasRight(item.right, $store.state.user.rights)"
                :key="item.to"
                :to="item.to">
              <v-list-item-icon>
                <v-icon>mdi-{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="padding-top: 2px;">{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="item.to==='/orders' && parseInt($store.state.updatingInfo.incoming)">
                <span class="menu-badge">{{ $store.state.updatingInfo.incoming }}</span>
              </v-list-item-action>
              <v-list-item-action v-if="item.to==='/orders' && parseInt($store.state.updatingInfo.confirm)">
                <span class="menu-badge" style="background: #2eb85c">{{ $store.state.updatingInfo.confirm }}</span>
              </v-list-item-action>
              <v-list-item-action style="margin-left: 0"
                                  v-if="item.to==='/orders' && parseInt($store.state.updatingInfo.waiting)">
                <span class="menu-badge" style="background: #f9b115">{{ $store.state.updatingInfo.waiting }}</span>
              </v-list-item-action>
              <v-list-item-action v-if="item.to==='/leads3' && $store.state.updatingInfo.lead_counts0">
                <span class="menu-badge">{{ $store.state.updatingInfo.lead_counts0 }}</span>
              </v-list-item-action>
              <v-list-item-action v-if="item.to==='/leads3' && $store.state.updatingInfo.lead_counts_checked">
                <span class="menu-badge" style="background: #8a93a2">{{ $store.state.updatingInfo.lead_counts_checked}}</span>
              </v-list-item-action>
              <v-list-item-action style="margin-left: 0"
                                  v-if="item.to==='/leads3' && $store.state.updatingInfo.lead_counts1">
                <span class="menu-badge" style="background: #f9b115">{{ $store.state.updatingInfo.lead_counts1 }}</span>
              </v-list-item-action>
              <v-list-item-action
                  v-if="item.to==='/feedback' && parseInt($store.state.updatingInfo.feedback_by_client)">
                <span class="menu-badge">{{ $store.state.updatingInfo.feedback_by_client }}</span>
              </v-list-item-action>
              <v-list-item-action
                  v-if="item.to==='/translation' && parseInt($store.state.updatingInfo.translation.total)">
                <span class="menu-badge">{{ $store.state.updatingInfo.translation.total }}</span>
              </v-list-item-action>
              <v-list-item-action v-if="item.to==='/feedback' && parseInt($store.state.updatingInfo.feedback)">
                <span class="menu-badge" style="background: #f9b115">{{ $store.state.updatingInfo.feedback }}</span>
              </v-list-item-action>
              <v-list-item-action style="margin-left: 0"
                                  v-if="item.to==='/feedback' && parseInt($store.state.updatingInfo.feedback5)">
                <span class="menu-badge" style="background: #2eb85c">{{ $store.state.updatingInfo.feedback5 }}</span>
              </v-list-item-action>

              <v-list-item-action v-if="item.to==='/tasks' && parseInt($store.state.updatingInfo.task.red)">
                <span class="menu-badge">{{ $store.state.updatingInfo.task.red }}</span>
              </v-list-item-action>
              <v-list-item-action v-if="item.to==='/leads' && $store.state.updatingInfo.leads_waiting*1">
                <span class="menu-badge"
                      style="background: #f9b115">{{ $store.state.updatingInfo.leads_waiting }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-list-group
                :value="false"
                :key="item.to"
                :prepend-icon="`mdi-${item.icon}`"
                v-else-if="item.children && item.children.some(child=>hasRight(child.right, $store.state.user.rights))"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-action
                    v-if="item.children.some(child=>child.to==='/cleaners') && parseInt($store.state.updatingInfo.cleaners_orange)">
                  <span class="menu-badge" style="background: #f9b115">{{
                      $store.state.updatingInfo.cleaners_orange
                    }}</span>
                </v-list-item-action>
                <v-list-item-action
                    v-if="item.children.some(child=>child.to==='/cleaners') && parseInt($store.state.updatingInfo.cleaners_red)">
                  <span class="menu-badge">{{ $store.state.updatingInfo.cleaners_red }}</span>
                </v-list-item-action>
              </template>
              <v-list-item v-for="child in item.children" :to="child.to" style="padding-left: 35px !important;"
                           @click="closeSidebar"
                           v-if="hasRight(child.right, $store.state.user.rights)">
                <v-list-item-icon>
                  <v-icon>mdi-{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="padding-top: 2px;">
                    {{ child.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action
                    v-if="child.to==='/cleaners' && parseInt($store.state.updatingInfo.cleaners_orange)">
                  <span class="menu-badge" style="background: #f9b115">{{
                      $store.state.updatingInfo.cleaners_orange
                    }}</span>
                </v-list-item-action>
                <v-list-item-action class="ml-0"
                                    v-if="child.to==='/cleaners' && parseInt($store.state.updatingInfo.cleaners_red)">
                  <span class="menu-badge">{{ $store.state.updatingInfo.cleaners_red }}</span>
                </v-list-item-action>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list>
        <v-list dense nav>
          <v-list-item to="/countries" v-if="$store.state.user.rights.includes('cities')" @click="closeSidebar">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="padding-top: 2px;">Настройки</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  name: 'TheSidebar',
  data() {
    return {
      ordersCount: '',
      rights: [],
      nav: [
        {
          name: 'Дашборд',
          to: '/dashboard',
          icon: 'monitor-dashboard',
          right: 'dashboard',
        },
        {
          name: 'Заказы',
          icon: 'clipboard-list-outline',
          to: '/orders',
          right: 'order',
        },
        {
          name: 'Аналитика',
          icon: 'poll',
          to: '/ordersSeo',
          right: 'ordersSeo',
        },
        {
          name: 'Задачи',
          icon: 'calendar-check',
          to: '/tasks',
          right: 'task',
        },
        {
          name: 'Клинеры',
          icon: 'account-group',
          children: [
            {
              name: 'Клинеры',
              to: '/cleaners/list',
              icon: 'account-group',
              right: 'cleaner',
            },
            {
              name: 'Воронка найма',
              to: '/cleaners/funnel',
              icon: 'filter',
              right: 'cleaner',
            },
            {
              name: 'Воронка адаптации',
              to: '/cleaners/funnel_adaptation',
              icon: 'filter',
              right: 'cleaner',
            },
            {
              name: 'Расписание',
              to: '/cleaners/calendar',
              icon: 'calendar-clock-outline',
              right: 'cleaner',
            },
            {
              name: 'ЗП клинеров',
              to: '/statistics/salary',
              icon: 'cash-multiple',
              right: 'cleaner',
            },
            {
              name: 'Наставники',
              icon: 'account-supervisor',
              to: '/cleanerMentor',
              right: 'cleanerMentor',
            },
            {
              name: 'Статистика',
              icon: 'chart-line',
              to: '/cleaners/salaryStats',
              right: 'cleaner'
            }
          ]
        },
        {
          name: 'Клиенты',
          icon: 'card-account-details-outline',
          children: [
            {
              name: 'Клиенты',
              icon: 'card-account-details-outline',
              to: '/clients',
              right: 'client'
            },
            {
              name: 'Рассылки',
              icon: 'bell',
              to: '/mailingLists',
              right: 'mailingListItem'
            },
          ]
        },
        {
          name: 'Лиды',
          to: '/leads',
          icon: 'bell-outline',
          right: () => !this.$store.state.newLeads() && this.$store.state.user.rights.includes('lead'),
        },
        {
          name: 'Лиды',
          to: '/leads2',
          icon: 'bell-outline',
          right: () => this.$store.state.newLeads() && !this.$store.state.user.rights.includes('lead3'),
        },
        {
          name: 'Лиды (NEW)',
          to: '/leads3',
          icon: 'bell-outline',
          right: () => this.$store.state.user.rights.includes('lead3'),
        },
        {
          name: 'История звонков',
          to: '/calls',
          icon: 'phone',
          right: 'calls',
        },
        {
          name: 'Качество',
          to: '/feedback',
          icon: 'phone-message',
          right: 'order',
        },
        {
          name: 'Перевод',
          to: '/translation',
          icon: 'translate',
          right: 'translation',
        },
        {
          name: 'Менеджеры',
          icon: 'account',
          children: [
            {
              name: 'Менеджеры',
              to: '/users',
              icon: 'account',
              right: 'users',
            },
            {
              name: 'Статистика',
              to: '/statistics/managers',
              icon: 'chart-line',
              right: 'users',
            },
            {
              name: 'Калькулятор зарплаты',
              to: '/statistics/salary_calculator',
              icon: 'calculator',
              right: 'users',
            }
          ]
        },
        {
          name: 'Регионы',
          icon: 'chart-scatter-plot',
          children: [
            {
              name: 'Выручка',
              to: '/regions/revenue',
              icon: 'chart-scatter-plot',
              right: ['regions', 'regions1'],
            },
            {
              name: 'Рекорды',
              to: '/regions/records',
              icon: 'trophy',
              right: 'order'
            },
            {
              name: 'Реклама',
              to: '/regions/adv',
              icon: 'google-ads',
              right: ['regions', 'regions2'],
            },
            {
              name: 'Дашборд',
              to: '/regions/dashboard',
              icon: 'monitor-dashboard',
              right: ['regions'],
            },
            {
              name: 'Графики',
              to: '/regions/charts',
              icon: 'chart-bar',
              right: ['regions'],
            },
            {
              name: 'Инструменты',
              to: '/regions/instruments',
              icon: 'tools',
              right: ['regions'],
            },
            {
              name: 'Функции',
              to: '/regions/features',
              icon: 'star-circle-outline',
              right: ['features'],
            },
            {
              name: 'Финансы',
              to: '/regions/balance',
              icon: 'currency-usd',
              right: ['balanceRegions'],
            },
          ]
        },
        {
          name: 'Другое',
          icon: 'more',
          children: [
            {
              name: 'Промокоды',
              to: '/promos',
              icon: 'ticket-percent-outline',
              right: 'promo',
            },
            {
              name: 'Промокоды (NEW)',
              to: '/promos2',
              icon: 'ticket-percent-outline',
              right: 'promonew',
            },
            {
              name: 'Metabase',
              to: '/metabase',
              icon: 'finance',
              right: () => (+this.$store.state.user.city.id===1 && this.$store.state.user.rights.includes('ordersSeo')),
            },
            {
              name: 'Коэффициенты',
              to: '/other/koefs',
              icon: 'battery-charging-high',
              right: 'koef',
            },
            {
              name: 'Финансы',
              to: '/other/balance',
              icon: 'currency-usd',
              right: 'balance',
            },
            {
              name: 'Склад',
              to: '/other/stock',
              icon: 'archive',
              right: 'stock',
            },
            {
              name: 'Партнерские промо',
              to: '/other/partner_promos',
              icon: 'handshake',
              right: 'certificate',
            },
            {
              name: 'СМС',
              to: '/statistics/sms',
              icon: 'cellphone-message',
              right: 'sms',
            },
            {
              name: 'Короткие ссылки',
              to: '/other/shortlink',
              icon: 'link-variant',
              right: 'shortLink',
            },
            {
              name: 'Блог',
              to: '/blog',
              icon: 'post-outline',
              right: 'blog',
            },
          ]
        },
      ],
    }
  },
  methods: {
    closeSidebar() {
      if (window.innerWidth < 480) {
        this.$store.state.sidebarShow = false
        localStorage.sidebarSize = false
      }
    },
    hasRight(rights, userRights) {
      if (typeof rights === 'function') return rights();
      return userRights.some(right => Array.isArray(rights) ? rights.includes(right) : rights === right)
    }
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    }
  },
  created() {
    this.rights = this.$store.state.user.rights;
  }
}
</script>
<style>
#app .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 20px;
}
</style>
